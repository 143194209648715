var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.forDialog
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          attrs: { icon: "", text: "", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.fetchQueryFiles()
                            },
                          },
                        },
                        on
                      ),
                      [_c("v-icon", [_vm._v("mdi-folder-open-outline")])],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            2921946360
          ),
        },
        [_c("span", [_vm._v("Browse query result files")])]
      )
    : _c(
        "v-btn",
        {
          attrs: { color: "primary", text: "" },
          on: {
            click: function ($event) {
              return _vm.fetchQueryFiles()
            },
          },
        },
        [_vm._v("Browse query result files")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }